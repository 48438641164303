import React from 'react'
import * as styles from './glassCard.module.css'
import image from '../../images/service.png'

const GlassCard = (props) => {

  return (
    <div className={styles.container}>
      <h3>{props.title}</h3>
      <img src={image} alt={props.title} />
      <a href='##'>VER MÁS</a>
    </div>
  )
}

export default GlassCard