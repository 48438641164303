import React from 'react'
import * as styles from "./aboutus.module.css"

const AboutUs = () => {
  
  return (
    <div className={styles.container}>
      <div className='container'>
        <h1>¿Quiénes Somos?</h1>
        <p>Somos una empresa creada para dar soluciones de ingenierías, basados en el cuidado y protección del medio ambiente y las comunidades en su entorno.</p>
        <p>Nuestra pasión es desarrollar proyectos de calidad y que cumplan estándares nacionales e internacionales relacionados con la protección de los diferentes ecosistemas.</p>
      </div>
      <div className='container flex'>
        <div className={styles.column}>
          <div>
            <h2>Misión</h2>
            <p>Analizar y Evaluar las variables de las industrias para dar soluciones sostenibles a través de la aplicación de estrategias de ingeniería amigables con el medio ambiente y la sociedad.</p>
          </div>
        </div>
        <div className={styles.column}>
          <div>
            <h2>Visión</h2>
            <p>Ser un referente de innovación en proyectos de ingeniería enfocados en el crecimiento rentable y sostenible para su empresa, basados en principios de cuidado ambiental.</p>
          </div>
        </div>
      </div>
      <div className='container'>
        <h2>Valores</h2>
        <ul className='flex'>
          <li>
            <i className="fa-solid fa-star-of-life"></i>
            <span>TRANSPARENCIA</span>
          </li>
          <li>
            <i className="fa-solid fa-star-of-life"></i>
            <span>DILIGENCIA</span>
          </li>
          <li>
            <i className="fa-solid fa-star-of-life"></i>
            <span>EXCELENCIA</span>
          </li>
          <li>
            <i className="fa-solid fa-star-of-life"></i>
            <span>SERVICIO INTEGRAL</span>
          </li>
          <li>
            <i className="fa-solid fa-star-of-life"></i>
            <span>RESPONSABILIDAD</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AboutUs