import React from "react"
import { ThemeContext } from "../components/layout/theme"
import * as styles from "./header.module.css"
import image from '../images/service.png'

const Header = () => {
   const theme = React.useContext(ThemeContext)

   return (
    <div className={styles.container}>
      <div className={styles.logo}>
         <img src={image} alt="ABBAGREEN ENGINEERING" width={50} height={50} />
         <span>LOGO</span>
      </div>
      <ul className={`${styles.menu} ${theme === "light" ? styles.light : styles.dark}`}>
         <li><a href="##">QUIÉNES SOMOS</a></li>
         <li><a href="##">SERVICIOS</a></li>
         <li><a href="##">CONTÁCTANOS</a></li>
      </ul>
      <div className={`${styles.menuMobile} ${theme === "light" ? styles.light : styles.dark}`}>
         <i class="fa-solid fa-bars"></i>
      </div>
    </div>
   )
}

export default Header