import React from "react"
import Header from "../components/header"
import Slider from "../components/slider"
import Info from "../components/sections/info"
import AboutUs from "../components/sections/aboutus"
import Services from "../components/sections/services"
import Footer from "../components/footer"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Abbagreen - Home Page</title>
      <Header />
      <Slider />
      <Info />
      <AboutUs />
      <Services />
      <Footer />
    </main>
  )
}

export default IndexPage
