import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ThemeContext } from "../components/layout/theme"
import * as styles from "./slider.module.css"

const images = [
  "https://images.unsplash.com/photo-1466611653911-95081537e5b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1578575437130-527eed3abbec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80",
]

const Slider = () => {
  const [index, setIndex] = React.useState(0)
  const [timer, setTimer] = React.useState(true)
  const theme = React.useContext(ThemeContext)

  React.useEffect(() => {
    if (timer) {
      setTimeout(() => {
        handlePage(index+1)
      }, 4500);
    }
  }, [index, timer])

  const handlePage = (newPage, timerOff) => {
    if (timerOff) setTimer(false)
    if (newPage > images.length-1) return setIndex(0)
    if (newPage < 0) return setIndex(2)

    return setIndex(newPage)
  }

  return (
  <div className={styles.container}>
    <AnimatePresence>
      <motion.img 
        key={index}
        src={images[index]}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "easeIn", duration: 0.5 }}
        exit={{ opacity: 0 }}
      />
    </AnimatePresence>
    <a href="##" className={styles.prev} onClick={() => handlePage(index-1, true)}>
      <i className="fa-solid fa-circle-chevron-left" />
    </a>
    <a href="##" className={styles.next} onClick={() => handlePage(index+1, true)}>
      <i className="fa-solid fa-circle-chevron-right" />
    </a>
    <div className={`${styles.pages} ${theme === "light" ? styles.light : styles.dark}`}>
      <ul>
        {images.map((v, i) => <li key={v} className={i === index ? styles.active : null}>{i+1}</li>)}
      </ul>
    </div>
  </div>
  )
}

export default Slider