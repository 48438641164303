import React from 'react'
import { ThemeContext } from '../layout/theme'
import GlassCard from '../common/glassCard'
import * as styles from './services.module.css'

const Services = () => {
  const theme = React.useContext(ThemeContext)

  return (
    <div className={`${styles.container}`}>
      <h1>Servicios</h1>
      <div className={`${styles.pattern} ${theme === 'light' ? styles.light : styles.dark }`}>
        <div className={`${styles.services}`}>
          <GlassCard title="AMBIENTE" />
          <GlassCard title="NEGOCIOS INTLS & IMPORT" />
          <GlassCard title="CIVIL" />
          <GlassCard title="MINERÍA & PETROLEO" />
          <GlassCard title="MECÁNICA" />
          <GlassCard title="TELECOMUNICACIONES" />
        </div>
      </div>
    </div>
  )
}

export default Services