import React from 'react'
import { ThemeContext } from "../../components/layout/theme";
import * as styles from "./info.module.css" 

const Info = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <div className={`flex ${styles.container} ${theme === "light" ? styles.light : styles.dark}`}>
      <div className='flex'>
        <i className="fa-solid fa-earth-americas"></i>
        <div className={styles.info}>
          <div className={styles.field}>Ubicación</div>
          <div className={styles.value}>Ecuador</div>
          <div className={styles.value}>Guayaquil</div>
        </div>
      </div>
      <div className='flex'>
        <i className="fa-solid fa-phone"></i>
        <div className={styles.info}>
          <div className={styles.field}>Teléfono</div>
          <div className={styles.value}>0998767307</div>
          <div className={styles.value}>0987358907</div>
        </div>
      </div>
      <div className='flex'>
        <i className="fa-solid fa-envelope"></i>
        <div className={styles.info}>
          <div className={styles.field}>Correo</div>
          <div className={styles.value}><a href="mailto:proyectos@abbagreen.net">proyectos@abbagreen.net</a></div>
        </div>
      </div>
    </div>
  )
}

export default Info