import React from 'react'
import * as styles from './footer.module.css'

const Footer = () => {

  return (
    <div className={`${styles.container}`}>
      <div className='container'>
          <p>©2022 Abbagreen Engineering - Todos los derechos reservados.</p>
      </div>
    </div>
  )
}

export default Footer